<template>
  <!-- Regions -->
  <div>
    <label for="region-filter">{{ $t("spots.filter_title_regions") }}</label>
    <select name="region-filter"
            id="region-filter"
            class="w-min bg-transparent font-bold text-theme-primary focus:outline-none text-left underline ml-1 appearance-none cursor-pointer"
            v-model="selectedRegionId"
            @input="updateValue($event.target.value)">
      <option :value="0">{{ $t("spots.filter_text_all_regions") }}</option>
      <option v-for="region in regions"
              v-bind:value="region.id">
        {{ region.name }}
      </option>
    </select>
  </div>
</template>
<script setup lang="ts">
const props = defineProps(["modelValue"]);
const emit = defineEmits(['update:modelValue'])

const selectedRegionId = ref<number>(props.modelValue);
const { data: regions, error } = await movRequest.useFetch<Array<Region>>("regions");

function updateValue(value: number) {
  selectedRegionId.value = value;
  emit('update:modelValue', value)
}

</script>
